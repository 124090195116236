import React, { Component } from 'react';
import ContactForm from './forms/ContactForm';
import ConfirmationMessageSend from './forms/ConfirmationMessageSend';
import KontaktInfo from './KontaktInfo';
import KontaktCategoryGroup from './KontaktCategoryGroup';
import './../../stylesheets/content.css'

class KontaktPage extends Component {
  constructor () {
	  super();
	  this.state = {
	  	progress: 0,
	  	messageId: false,
	  	order: {
	  		category: 
	  		{
	  			Trappa: false,
					Gulv: false,
					Garderober: false,
					DorerOgVinduer: false,
					Tomrerarbeider: false,
	  		},
	  		correct: false,
	  		email: false,
	  		mobile: false,
	  		name: false,
	  		lastname: false,
	  		message: false,
	  		accept: false,
	  	}
    }
    this.closeConfirmationView = this.closeConfirmationView.bind(this);
    this.saveValues = this.saveValues.bind(this);
    this.sendForm = this.sendForm.bind(this);
    this.selectCategory = this.selectCategory.bind(this);
  }

  saveValues(object) {
  	this.setState({
  		order: {
  			...this.state.order,
  			...object,
  		}
  	})
  }
  sendForm() {
    const order = this.state.order;
    const address = {
    	local: 'https://127.0.0.1/api/laskowskibygg/sendcontactform', 
    	global: 'https://laskowskicreative.eu/api/laskowskibygg/sendcontactform'}
    fetch(address.global, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(order)
    })
    .then((res) => res.json())      
    .then((json) => this.setState(
    	{messageId: json.messageId, 
    		progress: 'finish', 
	    	order: {
	    		correct: false,
					category: {
		  			Trappa: false,
						Gulv: false,
						Garderober: false,
						DorerOgVinduer: false,
						Tomrerarbeider: false,
	  			},
		  		email: false,
		  		mobile: false,
		  		message: false,
		  		name: false,
		  		lastname: false,
		  		accept: false,
		  	}
		  }
		))  
  }
  selectCategory(categoryObject) {
  	let category=categoryObject;
  	this.setState({
  		order: {
  			...this.state.order,
  			category,
  		}
  	})
  }

  closeConfirmationView(object){
  	this.setState({
  		...this.state,
  		...object
  	})
  }

	render() {
		return (
			<div>
		{this.state.progress !== ('finish') &&		
			<div id='contact-form'>
				{!this.props.setByKontaktRef && <KontaktInfo/>}
				<div className='contact-form-panel'>
					<KontaktCategoryGroup categoryStatus={this.state.order.category} selectCategory={this.selectCategory} kontaktCategories={this.props.kontaktCategories} />
					<ContactForm progress={this.state.progress} sendForm={this.sendForm} saveValues={this.saveValues} {...this.state.order} />
				</div>
			</div>
		}
		{
			(this.state.progress === 'finish' && this.state.messageId !== false) &&
			<ConfirmationMessageSend closeConfirmationView={this.closeConfirmationView} messageId={this.state.messageId} />
		}
		</div>
		) 
	}
}

export default KontaktPage;